import { DatePickerDate, TimeZone } from '@wix/editor-elements-corvid-utils';
import { SlotAvailability } from '@wix/ambassador-availability-calendar/types';
import {
  DropdownOptions,
  GetServiceAvailabilityResponse,
  IWixBookingAPI,
} from '../types/types';

type SelectedDate = {
  label: string;
  value: string;
};

export const getLocalDateTimeStartOfDay = (date: Date): string => {
  const localDate = getLocalDateFromDateTime(date);
  return `${localDate}T00:00:00`;
};

export const getTimestempFromDateTime = (
  date: Date,
  timeZone: string,
): string => {
  const localDate = getLocalDateFromDateTime(date);
  const options = { timeZone, hour12: false };
  const time = date.toLocaleTimeString([], options);
  return `${localDate}T${time}`;
};

export const getLocalDateFromDateTime = (date: Date): string => {
  const addZero = (num: number) => (num < 10 ? `0${num}` : `${num}`);
  return `${date.getFullYear()}-${addZero(date.getMonth() + 1)}-${addZero(
    date.getDate(),
  )}`;
};

export function getTodayLocalDateTimeStartOfDay(timezone: string): string {
  const dateTimeFormat = new Intl.DateTimeFormat('en-us', {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    timeZone: timezone,
  });
  const dateString = dateTimeFormat.format(new Date());
  const shiftedDate = new Date(dateString);

  return getLocalDateTimeStartOfDay(shiftedDate);
}

export const dateToEndOfDayDate = ({
  date,
  timeZone,
}: {
  date: Date;
  timeZone: string;
}): Date => {
  const parsedDate = new DatePickerDate({ type: 'Date', date, timeZone });

  return new Date(
    new TimeZone(timeZone).format({
      year: parsedDate.getYear(),
      month: parsedDate.getMonth(),
      day: parsedDate.getDay(),
      time: '23:59',
    }),
  );
};

export const dateToStartOfDayDate = ({
  date,
  timeZone,
}: {
  date: Date;
  timeZone: string;
}): Date => {
  const parsedDate = new DatePickerDate({ type: 'Date', date, timeZone });

  return new Date(
    new TimeZone(timeZone).format({
      year: parsedDate.getYear(),
      month: parsedDate.getMonth(),
      day: parsedDate.getDay(),
    }),
  );
};
// for (const slot of availability.slots) {
//   const slotDateFormatted = formatDateTimeToDatePickerView(
//     slot.startDateTime,
//     wixCodeApi,
//   );
export const getEnavledDatesForAmbassadorAvailability = async ({
  availabilityEntries,
  wixCodeApi,
  selectedLocationId,
}: {
  availabilityEntries: SlotAvailability[] | undefined;
  wixCodeApi: any;
  selectedLocationId: string;
}) => {
  const enabledDatesInCalendar: string[] = [];
  const enabledDatesArray: object[] = [];
  if (availabilityEntries) {
    for (const { slot } of availabilityEntries) {
      const startDate = slot?.startDate!;
      const slotDateFormatted = formatDateTimeToDatePickerView(
        startDate,
        wixCodeApi,
      );
      const hasLocation =
        slot?.location?.id === selectedLocationId ||
        slot?.location?.locationType === selectedLocationId;
      if (!enabledDatesInCalendar.includes(slotDateFormatted) && hasLocation) {
        enabledDatesInCalendar.push(slotDateFormatted);
        enabledDatesArray.push({
          startDate: new Date(new Date(startDate).toLocaleDateString('en-us')),
          endDate: new Date(new Date(startDate).toLocaleDateString('en-us')),
        });
      }
    }
  }
  return enabledDatesArray;
};

export const getEnavledDatesForVeloAvailability = ({
  availability,
  wixCodeApi,
  selectedLocationId,
}: {
  availability: GetServiceAvailabilityResponse;
  wixCodeApi: IWixBookingAPI;
  selectedLocationId: string;
}) => {
  const enabledDatesInCalendar: string[] = [];
  const enabledDatesArray: object[] = [];
  if (availability) {
    for (const slot of availability.slots) {
      const slotDateFormatted = formatDateTimeToDatePickerView(
        slot?.startDateTime,
        wixCodeApi,
      );
      if (
        !enabledDatesInCalendar.includes(slotDateFormatted) &&
        slot.location?.businessLocation?.id === selectedLocationId
      ) {
        enabledDatesInCalendar.push(slotDateFormatted);
        const slotDate = new Date(
          new Date(slotDateFormatted).toLocaleDateString(
            wixCodeApi.site.regionalSettings || 'en-us',
          ),
        );
        enabledDatesArray.push({
          startDate: slotDate,
          endDate: slotDate,
        });
      }
    }
  }
  return enabledDatesArray;
};

const formatDateTimeToDatePickerView = (
  date,
  wixCodeApi,
  withDateTime = false,
) => {
  const slotDate = typeof date === 'string' ? new Date(date) : date;
  const local = wixCodeApi.site.regionalSettings || 'en-us';
  const slotDateFormatted = slotDate.toLocaleDateString(local);
  if (withDateTime) {
    const dateTime = formatDateTimeToTimeSlotView(
      slotDate,
      wixCodeApi.site.timezone,
      local,
    );
    return `${slotDateFormatted} ${dateTime}`;
  }
  return slotDateFormatted;
};

export const formatDateTimeToTimeSlotView = (
  dateTime: Date,
  timeZone: string,
  locale?: string,
) => {
  const date = new Date(dateTime).toLocaleTimeString(locale, {
    hour12: true,
    hour: '2-digit',
    minute: '2-digit',
    timeZone,
  });
  let slectedformat;
  if (date.includes('AM')) {
    slectedformat = date.replace('AM', 'am');
  } else {
    slectedformat = date.replace('PM', 'pm');
  }
  return slectedformat;
};

export const getSlotsViewForSelectedDates = (
  selectedDate,
  availabilityEntries,
  wixCodeApi,
  selectedLocationId,
): SelectedDate[] => {
  const slotsForSelectedDate: string[] = [];
  const dateFormatted = formatDateTimeToDatePickerView(
    selectedDate,
    wixCodeApi,
  );
  const selectedDates: DropdownOptions[] = [];
  for (let index = 0; index < availabilityEntries.length; index++) {
    const slot = availabilityEntries[index].slot;
    const slotDateFormatted = formatDateTimeToDatePickerView(
      slot.startDate,
      wixCodeApi,
      true,
    );
    if (
      !slotsForSelectedDate.includes(slotDateFormatted) &&
      slotDateFormatted.includes(dateFormatted) &&
      slot?.location?.id === selectedLocationId
    ) {
      slotsForSelectedDate.push(slotDateFormatted);
      const slotTimeFormatted = formatDateTimeToTimeSlotView(
        slot.startDate,
        wixCodeApi.site.timezone,
        wixCodeApi.site.regionalSettings,
      );
      if (new Date(slot.startDate).getTime() > new Date().getTime()) {
        selectedDates.push({
          label: slotTimeFormatted,
          value: JSON.stringify([index]),
        });
      }
    } else if (
      slotsForSelectedDate.includes(slotDateFormatted) &&
      slotDateFormatted.includes(dateFormatted) &&
      slot?.location?.id === selectedLocationId
    ) {
      const slectedSlotsIndexList = JSON.parse(
        selectedDates[selectedDates.length - 1].value,
      );
      slectedSlotsIndexList.push(index);
      selectedDates[selectedDates.length - 1].value = JSON.stringify(
        slectedSlotsIndexList,
      );
    }
  }
  return selectedDates;
};

export const getVeloSlotsViewForSelectedDates = (
  selectedDate,
  availability,
  wixCodeApi,
  selectedLocationId,
): SelectedDate[] => {
  const slotsForSelectedDate: string[] = [];
  const dateFormatted = formatDateTimeToDatePickerView(
    selectedDate,
    wixCodeApi,
  );
  const selectedDates: DropdownOptions[] = [];
  for (let index = 0; index < availability.slots.length; index++) {
    const slot = availability.slots[index];
    const slotDateFormatted = formatDateTimeToDatePickerView(
      slot.startDateTime,
      wixCodeApi,
      true,
    );
    if (
      !slotsForSelectedDate.includes(slotDateFormatted) &&
      slotDateFormatted.includes(dateFormatted) &&
      slot.location?.businessLocation?.id === selectedLocationId
    ) {
      slotsForSelectedDate.push(slotDateFormatted);
      const slotTimeFormatted = formatDateTimeToTimeSlotView(
        slot.startDateTime,
        wixCodeApi.site.timezone,
        wixCodeApi.site.regionalSettings,
      );
      if (slot.startDateTime.getTime() > new Date().getTime()) {
        selectedDates.push({
          label: slotTimeFormatted,
          value: JSON.stringify([index]),
        });
      }
    } else if (
      slotsForSelectedDate.includes(slotDateFormatted) &&
      slotDateFormatted.includes(dateFormatted) &&
      slot.location?.businessLocation?.id === selectedLocationId
    ) {
      const slectedSlotsIndexList = JSON.parse(
        selectedDates[selectedDates.length - 1].value,
      );
      slectedSlotsIndexList.push(index);
      selectedDates[selectedDates.length - 1].value = JSON.stringify(
        slectedSlotsIndexList,
      );
    }
  }
  return selectedDates;
};

export const getNextMonth = (date: Date): Date => {
  let nextMonth;
  if (date.getMonth() === 11) {
    nextMonth = new Date(date.getFullYear() + 1, 0, date.getDate() + 3);
  } else {
    nextMonth = new Date(
      date.getFullYear(),
      date.getMonth() + 3,
      date.getDate(),
    );
  }
  return nextMonth;
};
