import { BulkResponse } from '@wix/ambassador-services-catalog-server/types';
import {
  createFormField,
  BookingRequestKeyMappings,
  getFieldType,
} from './serviceFormFieldMapper';
import { FormFieldViewInfoFieldType } from '@wix/forms-ui/types';
import { FormField } from '@wix/ambassador-services-catalog-server/http';
import { V2Rule, Operation } from '@wix/ambassador-wix-form-builder-web/types';
import { Optional } from '../types/types';

export enum ReservedFieldIds {
  SMS_REMINDER = 'sms-reminder-id',
}

export const createFormView = ({
  catalogData,
}: {
  catalogData: Optional<BulkResponse>;
}) => {
  const form = catalogData?.responseServices?.services?.[0].form!;
  const { customFields = [] } = form;
  const nameField = form.firstName;
  const fields = [
    createFormField({
      field: nameField,
      bookingsKeyMapping: {
        key: BookingRequestKeyMappings.FIRST_NAME,
      },
      fieldType: FormFieldViewInfoFieldType.TEXT,
    }),
    ...(form.lastName
      ? [
          createFormField({
            field: form.lastName,
            bookingsKeyMapping: {
              key: BookingRequestKeyMappings.LAST_NAME,
            },
            fieldType: FormFieldViewInfoFieldType.TEXT,
          }),
        ]
      : []),
    createFormField({
      field: form.email!,
      bookingsKeyMapping: {
        key: BookingRequestKeyMappings.EMAIL,
      },
      fieldType: FormFieldViewInfoFieldType.EMAIL,
    }),
    createFormField({
      field: form.phone!,
      bookingsKeyMapping: {
        key: BookingRequestKeyMappings.PHONE,
      },
      fieldType: FormFieldViewInfoFieldType.PHONE,
    }),
    ...customFields.map((field: FormField) => {
      const fieldType = getFieldType(field.valueType!);
      return createFormField({
        field,
        fieldType,
      });
    }),
  ];
  const isPhoneRequired = form?.phone?.userConstraints?.required;
  const isSMSReminderEnabled = false;
  const rule: V2Rule[] = [
    {
      enabled: isSMSReminderEnabled && !isPhoneRequired,
      condition: {
        eq: { fieldKey: ReservedFieldIds.SMS_REMINDER, value: true },
      },
      actions: [
        { fieldKeys: [form.phone!.fieldId!], operation: Operation.REQUIRED },
      ],
    },
  ];
  return {
    formId: form.id,
    fields,
    formViewInfo: {
      rule,
    },
  };
};
