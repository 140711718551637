import model from './model';
import { WidgetStateTypes } from './config/constants';
import { WixOOISDKAdapter } from '@wix/bookings-adapter-ooi-wix-sdk';
import { ConsultantApi } from '../../api/consultantApi';
import {
  EmptyStateType,
  Optional,
  IWixBookingAPI,
  FormField,
  Components,
  FormSubmission,
} from '../../utils/types/types';
import { BulkResponse } from '@wix/ambassador-services-catalog-server/types';
import { mapCatalogServiceToService } from '../../utils/mappers/serviceMapper';
import { setTimeoutAsync } from '../../utils/timeout/timeout';
import { getFirstIndividualService } from '../../utils/service/service';
import { WixBookingsCodeAPI } from '../../api/wixBookingsCodeApi';
import { setView } from './view/initialize';
import { getComponents } from './components';
import { setAvailabilitySection } from './view/sections/availability/availability';
import { setFormSection } from './view/sections/form/form';
import { SlotAvailability } from '@wix/ambassador-availability-calendar/types';
import { store } from '../../services/store/store';
import { updateWidgetState } from '../../services/store/consultantSlice';
import { APP_NAME } from '../../services/BiLogger/constants';
import { reportWidgetPageLoaded } from '../../services/BiLogger/BiLogger';

export default model.createController((controllerParams) => {
  const { $w, $widget, flowAPI, controllerConfig } = controllerParams;
  const fieldsValue: FormField[] = [];
  const formSubmission: FormSubmission = {};
  const wixCodeApi: IWixBookingAPI = controllerConfig.wixCodeApi;

  const initialErrors: EmptyStateType[] = [];
  const wixSdkAdapter: WixOOISDKAdapter = new WixOOISDKAdapter(
    controllerConfig.wixCodeApi,
    controllerConfig.platformAPIs,
    controllerConfig.appParams,
    controllerConfig.compId,
  );

  let components: Components;

  const biLogger = flowAPI.essentials.biLoggerFactory().logger();

  const { reportError } = flowAPI;

  const consultantApi = new ConsultantApi({
    wixSdkAdapter,
    reportError,
    flowAPI,
  });

  const wixBookingsCodeAPI = new WixBookingsCodeAPI({ wixCodeApi });

  const isDynamicPricingPerStaff = flowAPI.experiments.enabled(
    'specs.bookings.consultants.dynamicPricingPerStaff',
  );

  const isDynamicPricingCustom = flowAPI.experiments.enabled(
    'specs.bookings.consultants.dynamicPricingCustom',
  );

  const onError = (type: EmptyStateType) => initialErrors.push(type);

  const init = async (service, availabilityEntries) => {
    const { translations } = flowAPI;
    const availabilitySectionParams = {
      components,
      service,
      wixCodeApi,
      translations,
      wixBookingsCodeAPI,
      isDynamicPricingPerStaff,
      isDynamicPricingCustom,
    };
    const formSectionParams = {
      components,
      service,
      fieldsValue,
      formSubmission,
      consultantApi,
      translations,
    };
    setAvailabilitySection({
      ...availabilitySectionParams,
      ...{ consultantApi },
      ...{ availabilityEntries },
      fieldsValue,
      flowAPI,
      formSubmission,
      wixSdkAdapter,
      biLogger,
      $w,
    });
    setFormSection({
      ...formSectionParams,
      $w,
    });
  };

  return {
    pageReady: async () => {
      components = getComponents($w);
      components.widgetMultiStateBox.changeState(WidgetStateTypes.LOADING);
      setView({ components });
      let catalogData: Optional<BulkResponse>;
      setTimeoutAsync(async () => {
        catalogData = await consultantApi.getCatalogData({
          onError,
          serviceId: $widget.props.data.serviceId
            ? $widget.props.data.serviceId
            : null,
        });

        let serviceIndex: number = 0;
        let selectedService;
        if (!$widget.props.data.serviceId) {
          const aService = await getFirstIndividualService(catalogData);
          selectedService = aService?.service;
          serviceIndex = aService?.serviceIndex;
        } else {
          selectedService = $widget.props.data.serviceId;
        }
        if (
          selectedService &&
          (components.widgetMultiStateBox.currentState.id ===
            WidgetStateTypes.WITH_SERVICES ||
            components.widgetMultiStateBox.currentState.id ===
              WidgetStateTypes.LOADING)
        ) {
          const service = await mapCatalogServiceToService({
            catalogData,
            serviceIndex,
            consultantApi,
            wixCodeApi,
            isDynamicPricingPerStaff,
          });
          reportWidgetPageLoaded(biLogger, {
            widget_name: APP_NAME,
            origin: APP_NAME,
            serviceId: service.id,
            is_over_editor: wixSdkAdapter.isEditorMode(),
            is_owner: wixSdkAdapter.isOwner(),
            businessId: wixSdkAdapter.getInstanceId(),
            numOfServices: catalogData?.responseServices?.metadata?.items,
          });
          let availabilityEntries: SlotAvailability[] | undefined;
          store.dispatch(updateWidgetState(WidgetStateTypes.WITH_SERVICES));
          await init(service, availabilityEntries);
          $widget.fireEvent('widgetLoaded', {});
        } else {
          components.errorToast.collapse();
          components.noServicesText.text = flowAPI.translations.t(
            'app.empty-state.no-service-selected.subtitle',
          );
          store.dispatch(updateWidgetState(WidgetStateTypes.NOֹֹֹ_SERVICES));
          components.widgetMultiStateBox.changeState(
            WidgetStateTypes.NOֹֹֹ_SERVICES,
          );
        }
        // components.widgetMultiStateBox.changeState(relevantState);
      }, 50000);
    },
    exports: {},
  };
});
