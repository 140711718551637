import { ServiceLocation } from '@wix/bookings-uou-types';
import {
  Location as SlotLocation,
  LocationType,
} from '@wix/ambassador-availability-calendar/types';

export type Location = {
  id?: string;
  name?: string;
  address?: string;
  locationType: LocationType;
};

export const mapServiceLocationToLocation = (
  location: ServiceLocation,
): Location => {
  return {
    id: location.businessLocation?.id,
    name: location.businessLocation?.name,
    ...{
      address:
        location?.locationText ??
        location?.businessLocation?.address?.formattedAddress,
    },
    locationType: location?.type as any,
  };
};

export const mapSlotLocationToLocation = (
  location?: SlotLocation,
): Location => {
  return {
    id: location?.id,
    name: location?.name,
    address: location?.formattedAddress,
    locationType: location!.locationType!,
  };
};
