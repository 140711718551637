const Buffer = require('buffer/').Buffer;
// eslint-disable-next-line import/no-unresolved
const base62 = require('base-x')(
  '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz',
);

export const encode = (source) => {
  const buffer = Buffer.from(source);
  return base62.encode(buffer);
};

export const decode = (source) => {
  return base62.decode(source).toString();
};
