import {
  FormViewField,
  SubmissionValue,
  MultiLineAddressOptions,
} from '@wix/forms-ui/types';
import {
  ContactDetails,
  CustomFormField,
} from '@wix/ambassador-bookings-gateway/types';
import { Service } from './serviceMapper';
import { BusinessInfoBase } from '@wix/bookings-uou-types';
import { IWixBookingAPI } from '../types/types';

export interface BusinessInfo extends BusinessInfoBase {
  isSMSReminderEnabled: boolean;
}

export enum BookingRequestKeyMappings {
  ADDRESS = 'address',
  CONTACT_ID = 'contactId',
  COUNTRY_CODE = 'countryCode',
  EMAIL = 'email',
  FIRST_NAME = 'firstName',
  FULL_ADDRESS = 'fullAddress',
  LAST_NAME = 'lastName',
  PHONE = 'phone',
  TIMEZONE = 'timeZone',
  NO_OF_PARTICIPANTS = 'noOfParticipants',
  SMS_REMINDER = 'smsReminder',
}

export type Submission = {
  [k: string]: SubmissionValue;
};

export type BookingsKeyMapping = {
  key: BookingRequestKeyMappings;
};

export const mapFormSubmission = (
  formSubmission: Submission,
  service: Service,
  wixCodeApi: IWixBookingAPI,
) => {
  const additionalFields: CustomFormField[] = [];
  const contactDetails: ContactDetails = {};
  let sendSmsReminder = false;
  let numberOfParticipants = 1;
  service.formSchema!.fields!.forEach((field) => {
    const value = getSubmissionValue(formSubmission, field);
    if (value) {
      if (isNoOfParticipants(field)) {
        numberOfParticipants = Number(value);
      } else if (isSmsReminderField(field)) {
        sendSmsReminder = Boolean(value);
      } else if (isContactInfoField(field)) {
        appendContactInfoField(contactDetails, field, value, wixCodeApi);
      } else {
        appendAdditionalField(additionalFields, field, value);
      }
    }
  });
  return {
    contactDetails,
    additionalFields,
    sendSmsReminder,
    numberOfParticipants,
  };
};

const getSubmissionValue = (submission: Submission, field: FormViewField) => {
  if (
    typeof submission[field.externalId!] === 'boolean' &&
    !isSmsReminderField(field)
  ) {
    return submission[field.externalId!]!.toString();
  }
  return submission[field.externalId!];
};

const isSmsReminderField = (field: FormViewField) => {
  const bookingsKeyMapping: BookingsKeyMapping =
    field?.renderInfo?.metadata?.bookingsKeyMapping;
  return bookingsKeyMapping?.key === BookingRequestKeyMappings.SMS_REMINDER;
};

const isNoOfParticipants = (field: FormViewField) => {
  const bookingsKeyMapping: BookingsKeyMapping =
    field?.renderInfo?.metadata?.bookingsKeyMapping;
  return (
    bookingsKeyMapping?.key === BookingRequestKeyMappings.NO_OF_PARTICIPANTS
  );
};

const isContactInfoField = (field: FormViewField) => {
  return !!field?.renderInfo?.metadata?.bookingsKeyMapping;
};

const appendContactInfoField = (
  contactDetails: ContactDetails,
  field: FormViewField,
  value: any,
  wixCodeApi: IWixBookingAPI,
) => {
  const bookingsKeyMapping: BookingRequestKeyMappings =
    field?.renderInfo?.metadata?.bookingsKeyMapping?.key;
  const formattedValue =
    mapFormValueToContactInfoProperty?.[bookingsKeyMapping]?.(
      value,
      wixCodeApi,
    ) ?? value;
  if (formattedValue) {
    contactDetails[bookingsKeyMapping as keyof ContactDetails] = formattedValue;
  }
};
export const trimPhoneValue = (phone: string) =>
  // eslint-disable-next-line no-useless-escape
  phone.replace(/[\s\+\-\(\)\.+]/g, '');

const filterUndefined = (value: any) => !!value;

export const mapFormValueToContactInfoProperty: {
  [key in BookingRequestKeyMappings]?: (
    value: any,
    wixCodeApi: IWixBookingAPI,
  ) => any;
} = {
  phone: (
    value: string | { countryCode: string; prefix: string; phone: string },
  ) => {
    if (typeof value === 'object') {
      const { prefix, phone } = value;
      return phone ? `${prefix}${trimPhoneValue(phone)}` : '';
    }
    return trimPhoneValue(value);
  },
  fullAddress: (
    value: { [key in keyof MultiLineAddressOptions]: string },
    wixCodeApi: IWixBookingAPI,
  ) => {
    const { state, city, streetLine1, streetLine2 } = value;
    const isInJapanese = wixCodeApi.site.language === 'ja';
    const formattedAddress = isInJapanese
      ? `${[state, city, streetLine1].filter(filterUndefined).join('')}${
          streetLine2 ? ` ${streetLine2}` : ''
        }`
      : [streetLine1, streetLine2, city, state]
          .filter(filterUndefined)
          .join(', ');

    return {
      subdivision: state,
      city,
      addressLine: streetLine1,
      addressLine2: streetLine2,
      formattedAddress,
    };
  },
};

const appendAdditionalField = (
  additionalFields: CustomFormField[],
  field: FormViewField,
  value: any,
) => {
  additionalFields.push({
    id: field.externalId,
    value: String(value),
  });
};
