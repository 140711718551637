import { Slot as SlotAvailability } from '../types/types';
import { Slot } from '@wix/ambassador-availability-calendar/types';
import { Service } from './serviceMapper';
import { getTimestempFromDateTime } from '../dateAndTime/dateAndTime';
import { encode } from '../base62/base62';
import { BookingPolicy } from '@wix/ambassador-services-catalog-server/types';
import { ServiceType } from '@wix/bookings-uou-types';
import moment from 'moment-timezone';

export const mapSlotAvailabilityToSlot = ({
  slot,
  service,
}: {
  slot: SlotAvailability;
  service: Service;
}): Slot => {
  return {
    endDate: getTimestempFromDateTime(slot.endDateTime, service.timeZone!),
    location: service.location,
    resource: service.staffMembers[0]!,
    scheduleId: service.scheduleId,
    serviceId: slot.serviceId,
    sessionId: slot._id,
    startDate: getTimestempFromDateTime(slot.startDateTime, service.timeZone!),
    timezone: service.timeZone,
  };
};

export const mapSlotDtoToSlot = (slot: Slot, service: Service) => {
  const serviceType = ServiceType.INDIVIDUAL;
  const sessionId = encodeSessionData(slot, serviceType, service);
  return {
    _id: sessionId,
    serviceId: slot.serviceId,
    staffId: slot.resource?.id,
    start: slot.startDate,
    end: slot.endDate,
    capacity: 1,
    remainingSpots: 1,
  };
};

export const encodeSessionData = (
  session: Slot,
  serviceType: ServiceType,
  service: Service,
) => {
  return encode(
    JSON.stringify({
      rate: service.rate,
      scheduleOwnerId: session.serviceId,
      id: session,
      start: session.startDate,
      end: session.endDate,
      scheduleId: session.scheduleId,
      staffId: session.resource?.id,
      staffScheduleId: session.resource?.scheduleId,
      serviceType,
      locationId: session.location?.id,
      location: session.location || undefined,
    }),
  );
};

export function mapSlotToAvailabilitySlot(slot: Slot, service: Service) {
  const now = moment();
  const { futureBookingsPolicy, bookUpToXMinutesBefore } = getBookingsPolicy(
    service.policy,
  );
  const openBookingWindow = moment(slot.startDate).subtract(
    futureBookingsPolicy.limitXMinutesToTheFuture,
    'minutes',
  );
  const closeBookingWindow = moment(slot.startDate).subtract(
    bookUpToXMinutesBefore,
    'minutes',
  );

  const slotAlreadyEnded = moment(now).isAfter(slot.endDate);
  const isTooEarlyToBook =
    futureBookingsPolicy.shouldLimit && openBookingWindow.isAfter(now);
  const isTooLateToBook = closeBookingWindow.isBefore(now);

  return {
    ...slot,
    bookable: !isTooEarlyToBook && !isTooLateToBook && !slotAlreadyEnded,
    constraints: {
      bookableFrom: futureBookingsPolicy.shouldLimit
        ? openBookingWindow.toISOString()
        : undefined,
      bookableUntil: closeBookingWindow.toISOString(),
    },
  };
}

export function getBookingsPolicy({
  bookUpToXMinutesBefore = 0,
  futureBookingsPolicy = {},
  overrideBusinessPolicyFields = [],
  ...policy
}: BookingPolicy = {}) {
  const {
    shouldLimit = false,
    limitXMinutesToTheFuture = 0,
  } = futureBookingsPolicy;

  return {
    ...policy,
    futureBookingsPolicy: {
      shouldLimit,
      limitXMinutesToTheFuture,
    },
    bookUpToXMinutesBefore,
    overrideBusinessPolicyFields,
  };
}
