import {
  paymentOptions,
  FormStateTypes,
  ConsultantsWidgetComponentIds as ComponentIds,
} from '../../../config/constants';
import { FormFieldViewInfoFieldType, MessageType } from '@wix/forms-ui/types';
import { PaymentType } from '@wix/bookings-uou-types';
import { FlowAPI, I$W } from '@wix/yoshi-flow-editor';
import {
  Components,
  FormSubmission,
  FormField,
} from '../../../../../utils/types/types';
import { Service } from '../../../../../utils/mappers/serviceMapper';
import { ConsultantApi } from '../../../../../api/consultantApi';
import localeDataset from '@wix/locale-dataset-javascript';
import {
  validatePhoneNumber,
  doesFieldHasValue,
} from '../../../../../utils/form/formValidation';
import { store } from '../../../../../services/store/store';
import { getActionLabel } from '../../../../../utils/mappers/paymentMapper';

// const SMS_REMINDER_KEY = 'sms-reminder-id';

export const setFormSection = async ({
  components,
  service,
  fieldsValue,
  formSubmission,
  $w,
  consultantApi,
  translations,
}: {
  components: Components;
  service: Service;
  fieldsValue: FormField[];
  formSubmission: FormSubmission;
  $w: I$W;
  consultantApi: ConsultantApi;
  translations: FlowAPI['translations'];
}) => {
  const { formHeader } = service;
  const { t } = translations;
  const countriesOptions = localeDataset
    .getAllCountries()
    .filter((country) => country.dialCode && country.shortKey)
    .map((country) => {
      return {
        label: t(country?.shortKey || '') + ' ' + t(country.dialCode),
        value: country.dialCode,
      };
    });
  components.phoneCodeDropdown.options = countriesOptions;
  const onlineLabel = t('app.settings.display.payment-time-online');
  const offlineLabel = t('app.settings.display.payment-time-offline');
  const isBookingsOnEcom = await consultantApi.isBookingsOnEcom();
  components.formTilte.text = formHeader.title;
  if (formHeader.isDescriptionHidden || !formHeader.description) {
    components.formDescription.collapse();
  } else {
    components.formDescription.text = formHeader.description;
  }

  initFormRepeater(service, components, t);
  const { paymentOptionsDropdown } = components;
  if (
    service.payment.paymentDetails.paymentType === PaymentType.BOTH &&
    isBookingsOnEcom
  ) {
    paymentOptionsDropdown.options = [
      {
        label: onlineLabel,
        value: PaymentType.ONLINE,
      },
      {
        label: offlineLabel,
        value: PaymentType.OFFLINE,
      },
    ];
    paymentOptionsDropdown.expand();
  } else if (service.payment.paymentDetails.paymentType === PaymentType.BOTH) {
    paymentOptionsDropdown.options = [
      {
        label: onlineLabel,
        value: paymentOptions.WIX_PAY_ONLINE,
      },
      {
        label: offlineLabel,
        value: paymentOptions.WIX_PAY_OFFLIN,
      },
    ];
    paymentOptionsDropdown.expand();
  }
  paymentOptionsDropdown.value = paymentOptionsDropdown.options[0].value;

  components.paymentOptionsDropdown.onChange((e) => {
    const { actionLabels, isPendingApprovalFlow } = service;
    switch (e.target.value) {
      case paymentOptions.WIX_PAY_ONLINE:
      case PaymentType.ONLINE:
        components.bookButton.label = getActionLabel({
          paymentType: PaymentType.ONLINE,
          actionLabels,
          isPendingApprovalFlow,
          t,
        });
        break;
      case paymentOptions.WIX_PAY_OFFLIN:
      case PaymentType.OFFLINE:
        components.bookButton.label = getActionLabel({
          paymentType: PaymentType.OFFLINE,
          actionLabels,
          isPendingApprovalFlow,
          t,
        });
        break;
      default:
        break;
    }
  });

  components.shortInput.onChange((e) => {
    const currentField = getField(service, e.context.itemId);
    if (currentField.renderInfo.validationProperties.required) {
      const $item = $w.at(e.context);
      const shortInputErrorMessage = $item(ComponentIds.shortInputErrorMessage);
      if (doesFieldHasValue(e.target.value)) {
        shortInputErrorMessage.collapse();
      } else {
        shortInputErrorMessage.expand();
      }
    }
    return handleFormFieldOnChange(e, formSubmission);
  });

  components.longInput.onChange((e) => {
    const field = getField(service, e.context.itemId);
    if (field.renderInfo.validationProperties.required) {
      const $item = $w.at(e.context);
      const longInputErrorMessage = $item(ComponentIds.longInputErrorMessage);
      if (doesFieldHasValue(e.target.value)) {
        longInputErrorMessage.collapse();
      } else {
        longInputErrorMessage.expand();
      }
    }
    return handleFormFieldOnChange(e, formSubmission);
  });

  components.formCheckbox.onChange((e) => {
    const field = getField(service, e.context.itemId);
    if (field.renderInfo.validationProperties.required) {
      const $item = $w.at(e.context);
      const checkboxErrorMessage = $item(ComponentIds.checkboxErrorMessage);
      if (doesFieldHasValue(e.target.value)) {
        checkboxErrorMessage.collapse();
      } else {
        checkboxErrorMessage.expand();
      }
    }
    return handleFormFieldOnChange(e, formSubmission, e.target.checked);
  });

  components.phoneInput.onChange((e) => {
    const isValid = validatePhoneNumber(e.target.value);
    const phoneField = service?.formSchema?.fields?.find(
      (field) => field.externalId! === e.context.itemId,
    );
    if (isValid === MessageType.INVALID_PATTERN) {
      if (phoneField?.renderInfo?.validationProperties?.required) {
        components.phoneInputErrorMessage.expand();
      }
    } else {
      components.phoneInputErrorMessage.collapse();
      const savedCode = formSubmission[e.context.itemId]
        ? formSubmission[e.context.itemId]
        : '';
      const value = `${savedCode}${components.phoneInput.value}`;
      return handleFormFieldOnChange(e, formSubmission, value);
    }
  });

  components.phoneCodeDropdown.onChange((e) => {
    const hasValue = doesFieldHasValue(e.target.value);
    if (!hasValue) {
      components.phoneInputErrorMessage.expand();
    } else {
      components.phoneInputErrorMessage.collapse();
      const savedPhone = formSubmission[e.context.itemId]
        ? formSubmission[e.context.itemId]
        : '';
      const value = `${e.target.value}${savedPhone}`;
      return handleFormFieldOnChange(e, formSubmission, value);
    }
  });
  components.widgetMultiStateBox.changeState(
    store.getState().consultantWidget.widgetState,
  );
};

const getField = (service, itemId) => {
  return service.formSchema.fields.find((field) => field._id === itemId);
};

const initFormRepeater = (service, components, t) => {
  const { fields } = service.formSchema;
  components.formRepeater.data = fields;
  components.formRepeater.onItemReady(($item, itemData) => {
    generateFormFieldItem(itemData, $item, service, t);
  });
};

const generateFormFieldItem = (itemData, $item, service, t) => {
  const { type, validationProperties } = itemData.renderInfo;
  const { label } = itemData;
  switch (type) {
    case FormFieldViewInfoFieldType.TEXT:
      getRelevantFieldType(
        $item,
        ComponentIds.shortInput,
        FormStateTypes.SHORT_INPUT,
        label,
        validationProperties.required,
      );
      break;
    case FormFieldViewInfoFieldType.EMAIL:
      getRelevantFieldType(
        $item,
        ComponentIds.shortInput,
        FormStateTypes.SHORT_INPUT,
        label,
        validationProperties.required,
      );
      break;
    case FormFieldViewInfoFieldType.PARAGRAPH:
      getRelevantFieldType(
        $item,
        ComponentIds.longInput,
        FormStateTypes.LONG_INPUT,
        label,
        validationProperties.required,
      );
      break;
    case FormFieldViewInfoFieldType.CHECKBOX:
      getRelevantFieldType(
        $item,
        ComponentIds.formCheckbox,
        FormStateTypes.CHECKBOX,
        label,
        validationProperties.required,
      );
      break;
    case FormFieldViewInfoFieldType.PHONE:
      getRelevantFieldType(
        $item,
        ComponentIds.phoneInput,
        FormStateTypes.PHONE_VALIDATION,
        label,
        validationProperties.required,
      );
      shouldShowSmsReminderField($item, service, t);
      break;
    default:
  }
};

const getRelevantFieldType = (
  $item,
  compTitleId,
  State,
  label,
  isFieldRequired,
) => {
  $item(compTitleId).label = `${label} ${isFieldRequired ? '*' : ''}`;
  $item(ComponentIds.multiStateForm).changeState(State);
};

const handleFormFieldOnChange = (e, formSubmission, value = '') => {
  formSubmission[e.context.itemId] = value ? value : e.target.value;
};

const shouldShowSmsReminderField = ($item, service, t) => {
  const reminderComp = $item(ComponentIds.reminderCheckbox);
  if (service.businessInfo.isSMSReminderEnabled) {
    $item(ComponentIds.reminderCheckbox).label = t(
      'app.booking-form.fields.sms-reminder.label',
    );
    reminderComp.expand();
  } else {
    reminderComp.collapse();
  }
};
