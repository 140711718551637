import {
  FormFieldViewInfoFieldType,
  ValidationProperties,
} from '@wix/forms-ui/types';
import { FormViewField } from '@wix/ambassador-wix-form-builder-web/types';
import {
  FormField,
  ValueType,
} from '@wix/ambassador-services-catalog-server/http';

interface FormFieldView extends FormViewField {
  _id?: string;
  label?: string;
}

export const enum BookingRequestKeyMappings {
  ADDRESS = 'address',
  CONTACT_ID = 'contactId',
  COUNTRY_CODE = 'countryCode',
  EMAIL = 'email',
  FIRST_NAME = 'firstName',
  FULL_ADDRESS = 'fullAddress',
  LAST_NAME = 'lastName',
  PHONE = 'phone',
  TIMEZONE = 'timeZone',
  NO_OF_PARTICIPANTS = 'noOfParticipants',
  SMS_REMINDER = 'smsReminder',
}

export type BookingsKeyMapping = {
  key: BookingRequestKeyMappings;
};

export const createFormField = ({
  fieldType,
  field,
  bookingsKeyMapping,
}: {
  fieldType: FormFieldViewInfoFieldType;
  field?: FormField;
  bookingsKeyMapping?: BookingsKeyMapping;
  validations?: ValidationProperties;
}): FormFieldView => {
  return {
    _id: field?.fieldId,
    label: field?.label,
    externalId: field?.fieldId,
    renderInfo: {
      type: fieldType,
      metadata: {
        ...(bookingsKeyMapping ? { bookingsKeyMapping } : {}),
      },
      validationProperties: {
        required: field?.userConstraints?.required,
      },
    },
  };
};

export const getFieldType = (valueType: ValueType) => {
  switch (valueType) {
    case ValueType.CHECK_BOX:
      return FormFieldViewInfoFieldType.CHECKBOX;
    case ValueType.LONG_TEXT:
      return FormFieldViewInfoFieldType.PARAGRAPH;
    case ValueType.SHORT_TEXT:
      return FormFieldViewInfoFieldType.TEXT;
    default:
      return FormFieldViewInfoFieldType.TEXT;
  }
};
