import { doesFieldHasValue } from '../form/formValidation';
import { SubmissionValue } from '@wix/forms-ui/types';
import { Components } from '../types/types';
import { Service } from '../mappers/serviceMapper';
import { ConsultantsWidgetComponentIds as ComponentIds } from '../../components/Consultants Widget/config/constants';

export type AvailabilityValidation = {
  allFeildesValid: boolean;
  requiredFeilds: string[];
};

const itIsTypeDate = (date: Date) =>
  Object.prototype.toString.call(date) === '[object Date]';

export const validateDatePicker = (date: Date) => {
  if (!itIsTypeDate(date) || !date.getTime()) {
    return false; // MessageType.VALUE_REQUIRED;
  }
  return true;
};

const validateDropdown = (value: SubmissionValue) => {
  if (!doesFieldHasValue(value as string)) {
    return false; // MessageType.VALUE_REQUIRED;
  }
  return true;
};

export const validateAvailabilitySeciton = (
  components: Components,
  isBookingsOnEcom: boolean,
  service: Service,
): AvailabilityValidation => {
  const isDatePickerValid = validateDatePicker(components.datePicker.value);
  const isSlotValid = validateDropdown(components.slotsDropdown.value);
  const isCustomValid = components.customVariantsDropdown.isVisible
    ? validateDropdown(components.customVariantsDropdown.value)
    : true;
  const isLocationValid = !components.box10.collapsed
    ? validateDropdown(
        isBookingsOnEcom
          ? service.location.id
          : components.locationDropdown.value,
      )
    : true;
  const isStaffValid = !components.box12.collapsed
    ? validateDropdown(components.staffDropdown.value)
    : true;
  const requiredFeilds: string[] = [];
  if (!isDatePickerValid) {
    requiredFeilds.push(ComponentIds.datePickerErrorMessage);
  }
  if (!isSlotValid) {
    requiredFeilds.push(ComponentIds.timeDropdownErrorMessage);
  }
  if (!isLocationValid) {
    requiredFeilds.push(ComponentIds.locationDropdownErrorMessge);
  }
  if (!isStaffValid) {
    requiredFeilds.push(ComponentIds.staffDropdownErrorMessage);
  }
  if (!isCustomValid) {
    requiredFeilds.push(ComponentIds.customVariantsDropdownErrorMessage);
  }
  return {
    requiredFeilds,
    allFeildesValid:
      isDatePickerValid && isSlotValid && isLocationValid && isStaffValid,
  };
};
