import model from './model';

export default model.createController((controllerParams) => {
  const { $w, $widget } = controllerParams;
  return {
    pageReady: async () => {
      $widget.fireEvent('widgetLoaded', {});
    },
    exports: {},
  };
});
