import {
  QueryAvailabilityResponse,
  SlotAvailability,
} from '@wix/ambassador-availability-calendar/types';

export const getOnlyFutureSlotAvailabilities = (
  availableSlots?: QueryAvailabilityResponse,
): SlotAvailability[] => {
  const now = new Date();
  const onlyFutureEntries = availableSlots?.availabilityEntries?.filter(
    (availabilityEntry) => {
      const rfcStartTime = availabilityEntry?.slot?.startDate;
      return rfcStartTime && new Date(rfcStartTime) >= now;
    },
  );
  return onlyFutureEntries || [];
};
